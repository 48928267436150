<template>
  <v-card class="pa-4" outlined :loading="dataLoading">
    <v-card-title>Manage Your Teacher Listing Details</v-card-title>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" lg="6">
        <v-alert :value="alertVisible" type="success"
          >Form submitted successfully!</v-alert
        >
        <v-alert :value="alertErrorVisible" type="error"
          >Form submission failed. Please try again.</v-alert
        >

        <v-card-text class="text-h6 ml-0 pl-0">Personal Details</v-card-text>
        <v-radio-group v-model="availability" label="Availability" row>
          <v-radio
            v-for="(item, index) in availability_options"
            :value="item.value"
            :label="item.text"
            :key="index"
          ></v-radio>
        </v-radio-group>
        <v-autocomplete
          label="Grades"
          multiple
          v-model="grades"
          :items="grades_list"
          item-text="title"
          item-value="id"
        ></v-autocomplete>
        <v-autocomplete
          label="Subjects"
          multiple
          :items="subject_list"
          item-value="id"
          item-text="title"
          v-model="subjects"
        ></v-autocomplete>
        <!-- <v-text-field label="Video Link" v-model="video_link"></v-text-field> -->

        <div class="text-center">
          <!-- <v-btn color="primary" @click="dialog = true"> Add Video </v-btn> -->

          <v-dialog v-model="dialog" width="auto">
            <v-card>
              <video ref="webcam" width="640" height="480" autoplay></video>
              <v-card-actions>
                <v-btn @click="startRecording" :disabled="isRecording"
                  >Start Recording</v-btn
                >
                <v-btn @click="stopRecording" :disabled="!isRecording"
                  >Stop Recording</v-btn
                >
                <v-btn @click="dialog = false">close</v-btn>
                <!-- <v-btn
                  @click="downloadRecording"
                  :disabled="recordedChunks.length === 0"
                  >Download Recording</v-btn
                > -->
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <v-textarea v-model="about" label="About" rows="2"></v-textarea>
        <!-- <v-select
          multiple
          :items="mappedWeekList"
          label="Available for Meeting Weekdays"
          v-model="selectedWeekdays"
        ></v-select> -->
        <v-card-text>Select Available Day and Time for Meeting</v-card-text>
        <!-- <v-checkbox
          v-for="weekDay in mappedWeekList"
          v-model="selectedWeekdays"
          :key="weekDay"
          :label="weekDay.text"
          :value="weekDay.value"
        >
        </v-checkbox> -->
        <!-- <v-row align="center">
          <v-col
            v-for="weekDay in mappedWeekList"
            :key="weekDay.value"
            cols="6"
            md="4"
            lg="3"
          >
            <v-checkbox
              v-model="selectedWeekdays"
              :label="weekDay.text"
              :value="weekDay.value"
              class="mr-2"
            ></v-checkbox>
            <v-text-field
              v-model="selectedWeekdaysTime[weekDay.value]"
              :disabled="!selectedWeekdays.includes(weekDay.value)"
              label="Time"
              type="time"
              class="w-100"
            ></v-text-field>
          </v-col>
        </v-row> -->

        <v-row align="center">
  <v-col
    v-for="weekDay in mappedWeekList"
    :key="weekDay.value"
    cols="6"
    md="4"
    lg="3"
  >
    <v-checkbox
      v-model="selectedWeekdays"
      :label="weekDay.text"
      :value="weekDay.value"
      class="mr-2"
    ></v-checkbox>
    <v-text-field
      v-model="selectedWeekdaysTime[weekDay.value]"
      :disabled="!selectedWeekdays.includes(weekDay.value)"
      label="Time"
      type="time"
      class="w-100"
    ></v-text-field>
  </v-col>
</v-row>

      </v-col>
      <v-divider vertical class="hidden-md-and-down"></v-divider>
      <v-col cols="12" lg="6"
        ><v-card-text class="text-h6 ml-0 pl-0">Pricing</v-card-text>

        <v-text-field
          v-model="pricing.fee_monthly_online"
          label="Online Monthly Fee"
          type="number"
          :disabled="!pricing.fee_monthly_online_active"
          :rules="[
            (v) =>
              v >= fee_range_min || `Minimum fee should be ${fee_range_min}`,
          ]"
        >
          <template #prepend>
            <v-checkbox
              class="ma-auto"
              v-model="pricing.fee_monthly_online_active"
            ></v-checkbox>
          </template>
        </v-text-field>
        <v-text-field
          v-model="pricing.fee_course_online"
          label="Online Course Fee"
          type="number"
          :disabled="!pricing.fee_course_online_active"
          :rules="[
            (v) =>
              v >= fee_range_min || `Minimum fee should be ${fee_range_min}`,
          ]"
        >
          <template #prepend>
            <v-checkbox
              class="ma-auto"
              v-model="pricing.fee_course_online_active"
            ></v-checkbox>
          </template>
        </v-text-field>
        <v-text-field
          v-model="pricing.fee_monthly_offline"
          label="Offline Monthly Fee"
          type="number"
          :disabled="!pricing.fee_monthly_offline_active"
          :rules="[
            (v) =>
              v >= fee_range_min || `Minimum fee should be ${fee_range_min}`,
          ]"
        >
          <template #prepend>
            <v-checkbox
              class="ma-auto"
              v-model="pricing.fee_monthly_offline_active"
            ></v-checkbox>
          </template>
        </v-text-field>
        <v-text-field
          v-model="pricing.fee_course_offline"
          label="Offline Course Fee"
          type="number"
          :disabled="!pricing.fee_course_offline_active"
          :rules="[
            (v) =>
              v >= fee_range_min || `Minimum fee should be ${fee_range_min}`,
          ]"
        >
          <template #prepend>
            <v-checkbox
              class="ma-auto"
              v-model="pricing.fee_course_offline_active"
            ></v-checkbox>
          </template>
        </v-text-field>

        <v-card-text class="text-h6 ml-0 pl-0">Profile Video </v-card-text>

        <v-row align="center">
          <!-- Left side (Image for video) -->
          <v-col cols="8" class="text-center">
            <!-- You can replace the placeholder image with your actual video -->

            <video
              v-if="video_data"
              controls
              loop
              :src="video_data"
              height="300"
              width="350"
            ></video>
            <v-img
              src="./video_profile.jpg"
              width="200"
              height="150"
              v-else
            ></v-img>
          </v-col>

          <v-col cols="4" class="text-center">
            <v-btn color="primary" @click="dialog = true"> Update Video </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="submit" width="200" :loading="loading">
        Submit </v-btn
      ><v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      webcamStream: null,
      mediaRecorder: null,
      recordedChunks: [],
      recordedVideoFile: null,

      isRecording: false,
      subjects: [],
      alertVisible: false,
      alertErrorVisible: false,
      grades: [],
      availability: "",
      trialTimeType: "Minute",
      video_link: "",
      about: "",
      availability_options: [
        {
          text: "Online",
          value: "online",
        },
        {
          text: "Offline",
          value: "offline",
        },
        {
          text: "Both",
          value: "both",
        },
      ],
      grades_list: [],
      subject_list: [],
      listing_id: "",
      loading: false,
      dataLoading: false,
      pricing: {
        fee_monthly_online_active: false,
        fee_monthly_online: "",
        fee_monthly_offline_active: false,
        fee_monthly_offline: "",
        fee_course_online_active: false,
        fee_course_online: "",
        fee_course_offline_active: false,
        fee_course_offline: "",
      },
      fee_range_min: 500,
      selectedWeekdays: [],
      weekList: {
        sun: "Sunday",
        mon: "Monday",
        tue: "Tuesday",
        wed: "Wednesday",
        thu: "Thursday",
        fri: "Friday",
        sat: "Saturday",
      },
      selectedWeekdaysTime: {
        sun: "",
        mon: "",
        tue: "",
        wed: "",
        thu: "",
        fri: "",
        sat: "",
      },
      video_data: null,
    };
  },
  computed: {
    mappedWeekList() {
      return Object.keys(this.weekList).map((key) => ({
        text: this.weekList[key],
        value: key,
      }));
    },
  },
  mounted() {
    this.loadGrades();
    this.loadSubjects();
    this.getUserListingProfile();
  },
  methods: {
    uploadRecording() {
      const formData = new FormData();
      formData.append("video_profile", this.recordedVideoFile);
      if (this.recordedChunks.length > 0) {
        const blob = new Blob(this.recordedChunks, { type: "video/mp4" });
        formData.append("video_profile", blob, "recorded-video.mp4");
      }
      this.$api
        .post(`/listing/teacher-profile/add_update_video_profile/`, formData)
        .then(console.log("video"));
    },
    async startRecording() {
      try {
        this.webcamStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        this.$refs.webcam.srcObject = this.webcamStream;

        this.mediaRecorder = new MediaRecorder(this.webcamStream);

        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.recordedChunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = () => {
          this.webcamStream.getTracks().forEach((track) => track.stop());
        };

        this.mediaRecorder.start();
        this.isRecording = true;

        setTimeout(() => {
          this.stopRecording();
        }, 3 * 60 * 1000);
      } catch (error) {
        console.error("Error accessing webcam:", error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;

        const blob = new Blob(this.recordedChunks, { type: "video/mp4" });
        this.recordedVideoFile = new File([blob], "recorded-video.mp4", {
          type: "video/mp4",
        });
      }
    },

    downloadRecording() {
      if (this.recordedChunks.length === 0) return;

      const blob = new Blob(this.recordedChunks, { type: "video/mp4" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "recorded-video.mp4";
      a.click();
    },

    getUserListingProfile() {
      this.dataLoading = true;
      this.$api
        .get(`/listing/teachers/self-listing`)
        .then((res) => {
          this.grades = res.data.grades.map((item) => item.id);
          this.subjects = res.data.subjects.map((item) => item.id);
          // this.video_link = res.data.video_link;
          this.about = res.data.about;
          this.price_monthly = res.data.price_monthly;
          this.price_course = res.data.price_course;
          this.availability = res.data.availability;
          this.trial_time = res.data.trial_time;
          this.listing_id = res.data.id;
          this.pricing = res.data.pricing;
          this.selectedWeekdays = Object.keys(res.data.meeting_weekdays);
          this.selectedWeekdaysTime = res.data.meeting_weekdays;
          this.video_data = res.data.video_profile;
          // if (res.data.video_file) {
          //   this.video_file = this.video_file;
          // }
        })
        .finally(() => (this.dataLoading = false));
    },
    loadGrades() {
      this.$api
        .get("/categories/grades/")
        .then((res) => (this.grades_list = res.data.results));
    },
    loadSubjects() {
      this.$api
        .get("/categories/subjects/")
        .then((res) => (this.subject_list = res.data.results));
    },
    submit() {
      this.loading = true;
     
      this.$api
        .request({
          url: this.listing_id
            ? `/listing/teachers/${this.listing_id}/`
            : "/listing/teachers/",
          method: this.listing_id ? "patch" : "post",

          data: {
            ...(this.listing_id
              ? {
                  // video_file : this.recordedVideoFile,
                  // incoming_video_link: this.video_link,
                  about: this.about,
                  subjects: this.subjects,
                  grades: this.grades,
                  availability: this.availability,
                  pricing: this.pricing,
                  meeting_weekdays: this.selectedWeekdays,
                  incoming_about: this.about,
                  incoming_subjects: this.subjects,
                  incoming_grades: this.grades,
                  incoming_availability: this.availability,
                  pricing: this.pricing,
                  meeting_weekdays: this.selectedWeekdays.reduce(
                    (acc, cur) => {
                      acc[cur] = this.selectedWeekdaysTime[cur];
                      return acc;
                    },
                    {}
                  ),

                  // incoming_meeting_weekdays: this.selectedWeekdays,
                  incoming_meeting_weekdays: this.selectedWeekdays.reduce(
                    (acc, cur) => {
                      acc[cur] = this.selectedWeekdaysTime[cur];
                      return acc;
                    },
                    {}
                  ),
                }
              : {
                  // video_link: this.video_link,
                  about: this.about,
                  subjects: this.subjects,
                  grades: this.grades,
                  availability: this.availability,
                  pricing: this.pricing,
                  meeting_weekdays: this.selectedWeekdays.reduce(
                    (acc, cur) => {
                      acc[cur] = this.selectedWeekdaysTime[cur];
                      return acc;
                    },
                    {}
                  ),

                  // meeting_weekdays: this.selectedWeekdays,
                  // meeting_weekdays: this.selectedWeekdays.reduce((acc, cur) => {
                  //   acc[cur] = this.selectedWeekdaysTime[cur];
                  //   return acc;
                  // }, {}),
                }),
          },
        })
        .then(() => {
          return this.uploadRecording();
        })
        .then(() => {
          this.getUserListingProfile;
        })

        .then(() => {
          this.alertVisible = true;
          setTimeout(() => {
            this.alertVisible = false;
          }, 5000);

          this.getUserListingProfile();
        })
        .catch((error) => {
          this.alertErrorVisible = true;

          setTimeout(() => {
            this.alertErrorVisible = false;
          }, 5000);
        })
        .finally(() => {
          this.loading = false;
        });
      // // this.$api
      // //   .post(`/listing/teachers/`, {
      // //     video_link: this.video_link,
      // //     about: this.about,
      // //     subjects: this.subjects,
      // //     grades: this.grades,
      // //     availability: this.availability,
      // //     meeting_weekdays: this.selectedWeekdays,
      // //     pricing: this.pricing,
      // //   })
      // //   .then(() => {
      // //     this.getUserListingProfile;
      // //   })
      // //   .finally(() => {
      // //     this.loading = false;
      // //   });

      // this.$api
      //   .patch(`/listing/teachers/${this.listing_id}/`, {
      //     video_link: this.video_link,
      //     about: this.about,
      //     subjects: this.subjects,
      //     grades: this.grades,
      //     availability: this.availability,
      //     meeting_weekdays: this.selectedWeekdays,
      //     pricing: this.pricing,
      //   })
    },

    // submit() {
    //   this.loading = true;

    //   // Create a FormData object
    //   const formData = new FormData();

    //   // Append other data to the FormData
    //   formData.append("video_link", this.video_link);
    //   formData.append("about", this.about);

    //   // Append each subject and grade individually
    //   this.subjects.forEach((subject) => {
    //     formData.append("subjects", subject);
    //   });

    //   this.grades.forEach((grade) => {
    //     formData.append("grades", grade);
    //   });

    //   formData.append("availability", this.availability);
    //   formData.append("pricing", JSON.stringify(this.pricing));
    //   formData.append(
    //     "meeting_weekdays",
    //     JSON.stringify(
    //       this.selectedWeekdays.reduce((acc, cur) => {
    //         acc[cur] = this.selectedWeekdaysTime[cur];
    //         return acc;
    //       }, {})
    //     )
    //   );

    //   // Append the recorded video file to the FormData
    //   if (this.recordedChunks.length > 0) {
    //     const blob = new Blob(this.recordedChunks, { type: "video/mp4" });
    //     formData.append("video_file", blob, "recorded-video.mp4");
    //   }

    //   // Make the API request
    //   this.$api
    //     .request({
    //       url: this.listing_id
    //         ? `/listing/teachers/${this.listing_id}/`
    //         : "/listing/teachers/",
    //       method: this.listing_id ? "patch" : "post",
    //       data: formData,
    //     })
    //     .then(() => {
    //       this.alertVisible = true;
    //       setTimeout(() => {
    //         this.alertVisible = false;
    //       }, 5000);

    //       this.getUserListingProfile();
    //     })
    //     .catch((error) => {
    //       this.alertErrorVisible = true;

    //       setTimeout(() => {
    //         this.alertErrorVisible = false;
    //       }, 5000);
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
  },
  beforeDestroy() {
    if (this.webcamStream) {
      this.webcamStream.getTracks().forEach((track) => track.stop());
    }
  },
};
</script>

<style>
</style>